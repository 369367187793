class Map {
  constructor(el) {

    this.el = el
    this.firms = Array.from(el.querySelectorAll('.map__firm'))
    this.list = el.querySelector('.map__list')
    this.map = el.querySelector('.map__map')
    this.countries = el.querySelectorAll('g')
    this.addresses = el.querySelectorAll('.map__addresses')
    this.pins = el.querySelectorAll('[data-name="icon"]')

    if (window.innerWidth > 500) {
      this.firms.forEach(el => {
        el.addEventListener('mouseover', this.highlightMember.bind(this))
      })
    }

  }
  highlightMember(e) {
    e.preventDefault()

    const index = this.firms.indexOf(e.currentTarget)

    this.firms.forEach(el => { el.classList.remove('active') })
    e.currentTarget.classList.add('active')
    this.list.classList.add('fade')
    this.map.classList.add('fade')

    this.addresses.forEach(el => { el.classList.remove('active') })
    this.addresses[index].classList.add('active')

    const countries = this.el.querySelectorAll('g')
    const selectedCountries = e.currentTarget.dataset.locations.split(' ')

    countries.forEach(el => {
      el.style.opacity = '0.25'
      el.classList.remove('active')
    })

    selectedCountries.forEach(el => {
      const country = this.el.querySelector('#' + el)
      country.classList.add('active')
    })

  }
}

const initMap = () => {

  const section = document.querySelector('.map')

  if (section) new Map(section)

}

export default initMap