import gsap from "gsap"

class HeroSlider {
  constructor(el) {

    this.index = 0
    this.timer = 4000
    this.translations = el.querySelectorAll('.hero__ticker span')
    this.ticker = el.querySelectorAll('.hero__ticker')
    this.images = el.querySelectorAll('.hero__image')
    this.images[0].classList.add('active')
    
    setTimeout(() => { this.changeTranslation() }, 3000)

  }
  changeTranslation() {

    this.translations.forEach((el, i) => {
      if (i != this.translations.length - 1) {
        setTimeout(() => {
          gsap.to(this.translations[i], {duration: 0.5, y: '-100%', ease: 'power1.inOut' })
          gsap.to(this.translations[i + 1], {duration: 0.5, y: '0', ease: 'power1.inOut' })
          gsap.to(this.ticker, { duration: 0.3, delay: 0.1, width: this.translations[i + 1].offsetWidth + 'px', ease: 'power1.inOut'})
        }, 1600 * i)
      }
    })

    this.initSlider()

  }
  initSlider() {

    this.slideshowInterval = setInterval(this.changeSlide.bind(this), this.timer)

  }
  changeSlide() {

    this.index++

    if (this.index > this.images.length - 1) this.index = 0

    this.images.forEach(el => { el.classList.remove('active') })
    this.images[this.index].classList.add('active')

  }
}

const initHeroSlider = () => {

  const section = document.querySelector('.hero')

  document.fonts.ready.then(() => {
    if (section) new HeroSlider(section)
  })

}

export default initHeroSlider