import Flickity from 'flickity'

export default function initSlider() {

  const slider = document.querySelector('[data-slider]')

  if (!slider) return

  const header = document.querySelector('.slider__header')
  const content = slider.querySelectorAll('.slider__content')

  matchHeights()

  const flickity = new Flickity(slider, {
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    imagesLoaded: true,
    draggable: true,
    cellAlign: 'left'
  })

  flickity.on( 'change', () => {
    if (flickity.selectedIndex > 0 && window.innerWidth > 750) header.classList.add('hide')
    else header.classList.remove('hide')
  })

  function matchHeights() {

    let height = 0

    content.forEach(el => {
      el.style.height = 'auto'
      if (el.offsetHeight > height) height = el.offsetHeight
    })

    content.forEach(el => { el.style.height = height + 'px' })

  }

}