const menu = () => {

  const menuButton = document.querySelector('.header__menuButton')
  const header = document.querySelector('.header')
  const body = document.querySelector('body')

  menuButton.addEventListener('click', (e) => {
    e.preventDefault()
    header.classList.toggle('open')
    menuButton.classList.toggle('open')
    body.classList.toggle('noscroll')
  })

}

export default menu