import gsap from 'gsap'

export default function animations() { }

export function defaultAnim(el) {

  const heading = el.querySelectorAll('.line__wrap')
  const text = el.querySelectorAll('.js-text')
  const btn = el.querySelector('.js-btn')
  const items = el.querySelectorAll('.js-item')

  const tl = gsap.timeline()

  tl.to(heading, { duration: 1, y: '0%', stagger: 0.1, ease: 'power1.inOut' })
  if(text.length) tl.to(text, { duration: 1, x: 0, opacity: 1, ease: 'power3.out' }, '-=0.6')
  if(btn) tl.from(btn, { duration: 1, width: 0, x: -100, opacity: 0, ease: 'power2.out' }, '-=0.6')
  tl.to(items, {duration: 0.6, y: 0, opacity: 1, stagger: 0.1, ease: 'power2.out' }, '-=0.6')

}

export function services(el) {

  const heading = el.querySelectorAll('.line__wrap')
  const text = el.querySelector('.js-text')
  const btn = el.querySelector('.js-btn')
  const line = el.querySelectorAll('.services__item div')
  const item = el.querySelectorAll('.services__item span')

  const tl = gsap.timeline()

  tl.to(heading, { duration: 1, y: '0%', stagger: 0.1, ease: 'power1.inOut' })
  tl.to(text, { duration: 1, x: 0, opacity: 1, ease: 'power3.out' }, '-=0.6')
  tl.to(item, {duration: 0.7, x: 0, opacity: 1, stagger: 0.1, ease: 'power2.out' }, '-=0.6')
  tl.to(line, {duration: 0.7, width: '100%', stagger: 0.1, ease: 'power3.out' }, '<')

}