import Isotope from 'isotope-layout'

class PostFiltering {
  constructor(el) {

    this.filter = ''
    this.memberFilter = ''
    this.industryFilter = ''

    this.industry = el.querySelector('.insights__select--industry')
    this.member = el.querySelector('.insights__select--member')

    this.industry.addEventListener('change', this.filterIndustry.bind(this))
    this.member.addEventListener('change', this.filterMember.bind(this))
    
    this.initIso()

  }
  initIso() {

    this.iso = new Isotope( '.latest__posts', {
      itemSelector: '.latest__item',
      layoutMode: 'fitRows',
      percentPosition: true,
      transitionDuration: '0.5s'
    })

  }
  filterIndustry(e) {

    this.industryFilter = e.target.value
    if (this.industryFilter != '*') this.industryFilter = '.' + this.industryFilter 
    else this.industryFilter = ''

    this.filterPosts()

  }
  filterMember(e) {

    this.memberFilter = e.target.value
    if (this.memberFilter != '*') this.memberFilter = '.' + this.memberFilter
    else this.memberFilter = ''

    this.filterPosts() 

  }
  filterPosts() {

    this.filter = this.memberFilter + this.industryFilter

    if (this.filter === '') this.filter = '*'

    console.log(this.filter)
  
    this.iso.arrange({ filter: this.filter })
    
  }
}

const initPostFiltering = () => {

  const section = document.querySelector('[data-filters]')

  if (section) new PostFiltering(section)

}

export default initPostFiltering