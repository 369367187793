import gsap from 'gsap'

export default function introAnimation() {

  const body = document.querySelector('body')

  document.fonts.ready.then(() => {

    if (body.classList.contains('home')) {

      const tl = gsap.timeline({ delay: 0.6 })

      if (window.innerWidth > 960) {

        tl.to('.hero__preloader', { duration: 0.1, autoAlpha: 0 })
        tl.from('.hero__gradient', { duration: 1.8, opacity: 0, height: '100vh', ease: 'power3.out' })
        tl.from('.header', { duration: 2.0, y: 400, ease: 'power3.out' }, '-=1.6')  
        tl.from('.header__border', { duration: 1.4, width: 0, ease: 'power2.inOut' }, '-=1.8')
        tl.from(['.header__logo', '.header .menu-item'], { duration: 1.4, y: 100, stagger: 0.1, ease: 'power3.out' }, '-=1.8')
        tl.from('.hero__imageHolder', { duration: 1.4, height: 0, ease: 'power3.inOut', clearProps: 'all' }, '-=1.4')
        tl.to('.hero__heading .line__wrap', { duration: 1, y: '0%', stagger: 0.1, ease: 'power1.inOut' }, '-=1.4')
        tl.from('.hero__button', { duration: 1, width: 0, x: -100, opacity: 0, ease: 'power2.out' }, '-=1')

      } else {

        tl.to('.hero__preloader', { duration: 0.1, autoAlpha: 0 })
        tl.from('.header', { duration: 2.0, y: 200, ease: 'power3.out' })
        tl.from('.header__border', { duration: 1.4, width: 0, ease: 'power2.inOut' }, '-=1.8')
        tl.from(['.header__logo', '.header .menu-item'], { duration: 1.4, y: 50, stagger: 0.1, ease: 'power3.out' }, '-=1.8')
        tl.to('.hero__heading .line__wrap', { duration: 1, y: '0%', stagger: 0.1, ease: 'power1.inOut' }, '-=1.4')
        tl.from('.hero__button', { duration: 1, width: 0, x: -100, opacity: 0, ease: 'power2.out' }, '-=1')
        
      }

    }

  })
}