const readMore = () => {

  const link = document.querySelectorAll('.latest__more')

  link.forEach(el => el.addEventListener('click', revealText))

  function revealText(e) {
    e.preventDefault()
    e.currentTarget.previousElementSibling.classList.add('open')
    e.currentTarget.classList.add('hide')
  }

}

export default readMore