import gsap from 'gsap'
import SplitText from '../libraries/SplitText.min.js'

gsap.registerPlugin(SplitText)

export default function initSplitText() {

  document.fonts.ready.then(() => {

    const linesToSplit = document.querySelectorAll('[data-split]')

    const lineSplit = new SplitText(linesToSplit, { type: 'lines', linesClass: 'line' })

    lineSplit.lines.forEach(el => {

      const text = el.innerHTML
      const wrap = document.createElement('div')
      wrap.classList.add('line__wrap')
      wrap.innerHTML = text

      el.innerHTML = ''
      el.appendChild(wrap)

    })

  })

  // window.addEventListener('resize', () => {
  //   lineSplit.split()
  // })

}