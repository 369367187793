const videoPlay = (e) => {

  const video = document.querySelector('.videoBlock__player')
  const play = document.querySelector('.videoBlock__play')

  if (!video) return

  play.addEventListener('click', (e) => {
    e.preventDefault()
    video.play()
    video.controls = 'controls'
    play.classList.add('hide') 
  })

}

export default videoPlay