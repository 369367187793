// Import Scss DO NO DELETE
import '../scss/main.scss';

// Import external dependencies
import "babel-polyfill";
import 'custom-event-polyfill/polyfill.js';

// Bundle module files on build
// https://medium.com/@svinkle/getting-started-with-webpack-and-es6-modules-c465d053d988
// Issue with below is order of load is set by alphabetical naming of files...?
const requiredModules = require.context("./modules/", true, /\.(js)$/i);
requiredModules.keys().map(key => {
  requiredModules(key).default();
});