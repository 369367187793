class Services {
  constructor(el) {

    this.items = Array.from(el.querySelectorAll('.communities__item'))
    this.firms = el.querySelectorAll('.communities__firms')
    this.list = el.querySelector('.communities__list')
    this.text = el.querySelector('.communities__text')
    this.holder = el.querySelector('.communities__holder')

    this.items.forEach(el => {
      el.addEventListener('click', this.selectCommunity.bind(this))
    })

  }
  selectCommunity(e) {

    const index = this.items.indexOf(e.currentTarget)

    if (e.currentTarget.classList.contains('active')) {

      this.firms.forEach(el => { el.classList.remove('active') })
      this.items.forEach(el => { el.classList.remove('active') })
      this.list.classList.remove('fade')
      this.text.classList.remove('hide')
      this.holder.style.height = this.text.offsetHeight + 'px'

    } else {

      this.firms.forEach(el => { el.classList.remove('active') })
      this.items.forEach(el => { el.classList.remove('active') })
      this.firms[index].classList.add('active')
      e.currentTarget.classList.add('active')
      this.list.classList.add('fade')
      
      if (window.innerWidth > 750) {
        this.text.classList.add('hide')
        this.holder.style.height = this.firms[index].offsetHeight + 'px'
      }

    }

  }
}

const initServices = () => {

  const section = document.querySelector('.communities')

  if (section) new Services(section)

}

export default initServices