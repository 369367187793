import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as animations from './animations.js'

gsap.registerPlugin(ScrollTrigger)

const isInview = () => {

  const sections = document.querySelectorAll('[data-inview]')

  if (!sections) return

  document.fonts.ready.then(() => {
  
    sections.forEach(section => {

      ScrollTrigger.create({
        trigger: section, 
        start: 'top 80%',
        end: 'bottom bottom',
        once: true,
        toggleClass: 'is-inview',
        onEnter: () => {
          switch (section.dataset.inview) { 
            case 'default': 
              animations.defaultAnim(section)
            break
            case 'services':
              animations.services(section)
            break
          }
        }
      })
      
    })

  })

}

export default isInview